
import { Component, Vue } from "vue-property-decorator";
import { authService } from "@/auth/AuthService";
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import { mixins } from "vue-class-component";
import { Validation, validationMixin } from "vuelidate";

@Component({
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
      },
      passwordConfirm: {
        sameAsPassword: sameAs("password"),
      },
      email: {
        required,
      },
      code: { required },
    },
  },
})
export default class VerifyForgetPasswordPage extends mixins(validationMixin) {
  form: any = {
    email: null,
    password: null,
    passwordConfirm: null,
    code: null,
  };
  // email: string | null = "";
  // password: string | null = "";
  // passwordConfirm: string | null = "";
  // code: string | null = "";
  serverMessage: string | null = "";
  status = true;
  loading = false;
  timeout?: NodeJS.Timeout;

  mounted() {
    const { email, code } = this.urlQuery;
    // console.log('mounted', email, code);
    this.form = { ...this.form, email, code };
  }

  get urlQuery() {
    const q = this.$route.query;
    return {
      email: `${q.email || ""}` || null,
      code: `${q.code || ""}` || null,
      success: !!+`${q.success || ""}`,
    };
  }

  onSubmit() {
    this.$v.form.$touch();
    console.log(this.$v.form.$anyError, this.$v.form);
    if (this.$v.form.$anyError) {
      return;
    }

    this.loading = true;
    this.$nextTick(async () => {
      await this.handleSubmit();
      this.loading = false;
    });
  }

  async handleSubmit() {
    try {
      const { email, password, code } = this.form;
      const { data } = await authService.verifyForgetPassword({
        email,
        password,
        code,
      });
      if (data.status === "okay") {
        this.status = true;
        this.serverMessage = `Password updated`;
        await this.redirectSuccess();
      }
    } catch (e) {
      this.status = false;
      console.error(e);
      this.serverMessage = "Could not verify your password. Please make sure your verification code is correct.";
    }
  }

  async redirectSuccess() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.$router.push({
        path: "/login",
        query: {
          email: this.form.email,
          success: "1",
        },
      });
    }, 1000);
  }

  validateState(name: string) {
    // console.log("validateState");
    const { $dirty, $error } = this.$v.form[name] as Validation;
    // console.log(name, $dirty ? !$error : null);
    return $dirty ? !$error : null;
  }
}
